<template>
  <nav
    class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top"
  >
    <div class="container-fluid">
      <!-- <div class="navbar-wrapper">
        <a class="navbar-brand" href="/administrator">Dashboard</a>
      </div> -->
      <div
        id="burger"
        :class="{ active: isBurgerActive }"
        @click.prevent="toggle"
      >
        <slot>
          <button type="button" class="burger-button" title="Menu">
            <span class="burger-bar burger-bar--1"></span>
            <span class="burger-bar burger-bar--2"></span>
            <span class="burger-bar burger-bar--3"></span>
          </button>
        </slot>
      </div>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a
              class="nav-link"
              href="javascript:;"
              id="navbarDropdownProfile"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="material-icons">person</i>
              <p class="d-lg-none d-md-block">Account</p>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdownProfile"
            >
              <a class="dropdown-item" href="/administrator">ユーザーの一覧</a>
              <a class="dropdown-item" href="/addUser">ユーザの追加</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item"  v-on:click="logout">ログアウト</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script >
import { store, mutations } from "../assets/js/store.js";
import axios from "axios";
export default {
  data() {
    return {
      countSession : 0,
    };
  },
  computed: {
    isBurgerActive() {
      return store.isNavOpen;
    },
  },
  methods: {
    logout(){
      this.logoutSesion();
      // this.$cookies.remove("token");
      // this.$cookies.remove("usertoken");
      this.$router.push("/login");
    },
    logoutSesion(){
      var token = this.$cookies.get("token");
      // if(token!= null || token != ''){
      //   this.delAuthen(token);
      // }
      this.$cookies.remove("token");
      this.$cookies.remove("usertoken");
    },
    delAuthen(tokenid) {
      axios
        .post(`${this.OPENVIDU_SERVER_URL}/vti/delAuthen`, {
          token: tokenid,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data == "success") {
              this.flg = true;
            }
          }
        })
        .catch((error) => console.log(error));
    },
    toggle() {
      mutations.toggleNav()
    },
    checkSession(sessionId) {
      // return new Promise((resolve, reject) => {
      return axios
        .get(`${this.OPENVIDU_SERVER_URL}/openvidu/api/sessions/${sessionId}`, {
          auth: {
            username: "OPENVIDUAPP",
            password: this.OPENVIDU_SERVER_SECRET,
          },
        })
        .then((response) => {
          console.log(JSON.parse(response.data.connections.numberOfElements))
          if(JSON.parse(response.data.connections.numberOfElements) == 1){
            this.countSession = 1;
          }
        })
        .catch((error) => {
          console.log(error);
          this.flagSession = 0;
          // console.log(this.flagSession);
        });
      // });
    },
  },
};
</script>
<style >
.hidden {
  visibility: hidden;
}
.show .dropdown-menu a{
    font-size: 15px;
}

button {
  cursor: pointer;
}

/* remove blue outline */
button:focus {
  outline: 0;
}

.burger-button {
  position: relative;
  height: 30px;
  width: 32px;
  display: block;
  z-index: 999;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  pointer-events: all;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar {
  background-color: #130f40;
  position: absolute;
  top: 50%;
  right: 6px;
  left: 6px;
  height: 2px;
  width: auto;
  margin-top: -1px;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar--1 {
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
}

.burger-bar--2 {
  transform-origin: 100% 50%;
  transform: scaleX(0.8);
}

.burger-button:hover .burger-bar--2 {
  transform: scaleX(1);
}

.no-touchevents .burger-bar--2:hover {
  transform: scaleX(1);
}

.burger-bar--3 {
  transform: translateY(6px);
}

#burger.active .burger-button {
  transform: rotate(-180deg);
}

#burger.active .burger-bar {
  background-color: -internal-light-dark(black, white);
}

#burger.active .burger-bar--1 {
  transform: rotate(45deg);
}

#burger.active .burger-bar--2 {
  opacity: 0;
}

#burger.active .burger-bar--3 {
  transform: rotate(-45deg);
}

</style>


