<template>
  <div class="sidebar">
    <div
      class="sidebar-backdrop"
      @click="closeSidebarPanel"
      v-if="isPanelOpen"
    ></div>
    <transition name="slide">
      <div v-if="isPanelOpen" class="sidebar-panel">
        <!-- <slot></slot> -->
        <div class="logo">
          <a
            href="./administrator"
            class="simple-text logo-normal navi"
          >
            K-Link
          </a>
        </div>
        <div class="sidebar-wrapper">
          <ul class="nav">
            <li class="nav-item" v-bind:class="{ active: activate1 }">
              <a class="nav-link" href="./administrator">
                <i class="material-icons">dashboard</i>
                <p class="navi">ユーザーの一覧</p>
              </a>
            </li>
            <li class="nav-item" v-bind:class="{ active: activate2 }">
              <a class="nav-link" href="./addUser">
                <i class="material-icons">person</i>
                <p class="navi">ユーザの追加</p>
              </a>
            </li>
          </ul>
        </div>
        <div class="footer">
          <ul class="nav">
            <li class="nav-item">
              <a class="nav-link footer-nav" v-on:click="logout">
                <i class="fa fa-sign-out"></i>
                <p class="navi">ログアウト</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script >
import { store, mutations } from "../assets/js/store.js";
export default {
  name: "Sidebar",
  data() {
    return {
      activeClass: true,
      inactiveClass: false,
      active: "active",
      inactive: "inactive",
      activate1: false,
      activate2: false,
    };
  },
  mounted() {
    if (this.$router.currentRoute.name === "Administrator") {
      this.activate1 = true;
    } else if (this.$router.currentRoute.name === "Create User") {
      this.activate2 = true;
    }
  },
  computed: {
    isPanelOpen() {
      return store.isNavOpen;
    },
  },
  methods: {
    closeSidebarPanel: mutations.toggleNav,
    isActive(menuItem) {
      return this.$router.currentRoute.name === menuItem;
    },
    logout(){
      this.$cookies.remove("token");
      this.$cookies.remove("usertoken");
      this.$router.push("/login");
    },
  },
};
</script>
<style >
/* Sidebar */
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  color: white;
  text-align: center;
  z-index: 999;
  width: 260px;
}
.footer .nav {
  border-top: 1px solid;
  margin-right: auto;
  margin-left: auto;
}
.footer .footer-nav {
  width: 195px;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}
.navi {
  color: white;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.sidebar-panel {
  /* overflow-y: auto; */
  background-color: #130f40;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  padding: 3rem 20px 2rem 20px;
  width: 260px;
}
.active a {
  /* border: 1px solid; */
  border-radius: 7px;
  background: linear-gradient(#d84c0a, orange);
}
.active p {
  color: aliceblue;
}
</style>
